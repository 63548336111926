import { useState } from "react";
import logo from './logo.svg';
import './App.css';

//Import images
import facilitationImage from "./images/Facilitation.png";
import sysEngImage from "./images/SySeng.png";
import techImage from "./images/Tech.png";
import testImage from "./images/Test.png";
import trafficImage from "./images/Traffic.png";
import uxImage from "./images/UX.png";
import logoImage from "./images/logo.png";

//import buttons
import valButton from "./images/buttons/Vals.svg";
import aboutButton from "./images/buttons/AboutUs.svg";
import controlButton from "./images/buttons/Control.svg";
import itsButton from "./images/buttons/ITS.svg";
import otherButton from "./images/buttons/Other.svg";
import personalButton from "./images/buttons/Personal.svg";
import sysButton from "./images/buttons/Sys.svg";
import techButton from "./images/buttons/Tech.svg";
import uxButton from "./images/buttons/UX.svg";
import wksButton from "./images/buttons/Wks.svg";


//COMPONENTS

/*
C.1.2.a.1 - Navigation Button. Will update the Info Pane
*/
function DisciplineButton({ name, backgroundImage, onDisClick, onEscape }) {
    return (
        <button className="disciplinebutton" 
        style={{ backgroundImage: `url(${backgroundImage})` }} // Set the background image here
        onClick={onDisClick} 
        onMouseEnter={onDisClick} 
        onMouseLeave={onEscape}>
            
        </button>
    );
}

/*
C.1.2 - Button Pane. Will break up the list of buttons into threes and display each button Row
*/
/*
TITLE: ButtonPane
FUNCTIONALITY: Renders a list of buttons - CSS provides grid behaviour
DESCRIPTION: Responsive grid 
*/
function ButtonPane({ buttonAction, onHoverEscape }) {
    function handleClick(name) {
        buttonAction(name);
    }

    return (
        <div className="buttonpane" >
            <div className="buttonpane-inner">
                {Object.values(disciplineData).map((discipline) => (
                    <DisciplineButton
                        key={discipline.name}
                        name={discipline.name}
                        backgroundImage={discipline.image.but} // Passing the button image
                        onDisClick={() => {
                            handleClick(discipline.name);
                        }}
                        onEscape={onHoverEscape}
                    />
                ))}
            </div>
        </div>
    );
}

/*
C.1.1 - Info Pane. Will display the current discipline information
*/
function InfoPane({ disciplineDataPoint }) {
    if (!disciplineDataPoint) {
        return (
            <div className="logopane" style={{ backgroundImage: `url(${logoImage})` }}>
                <div className="infopane" style={{ background: 'transparent' }} />
            </div>
        );
    }
    //Extract all data to be used
    const {
        name,
        description,
        image: { src, alt },
    } = disciplineDataPoint;

    //return code block

    return (
        <div className="logopane" style={{ backgroundImage: `url(${logoImage})` }}>
            <div className="infopane">
                <div className="infoimagecontainer">
                    <img className="infoimage" src={src} alt={alt} />
                </div>
                <div className="infotext">{description}</div>
            </div>
        </div>
    );
}

/*
C.1 - DesktopView. Will display the entire app if in Desktop Mode
*/
function DesktopView() {
    //Holds the Discipline Data being shown
    const [shownDiscipline, setShownDiscipline] = useState(null);

    function displayDiscipline(disciplineName) {
        //generate the infoPane
        setShownDiscipline(disciplineData[disciplineName]);
    }

    function resetView() {
        //reset the infoPane
        setShownDiscipline(null);
    }

    return (
        <div className="desktopview" >
            <InfoPane disciplineDataPoint={shownDiscipline} />
            <ButtonPane buttonAction={displayDiscipline} onHoverEscape={resetView} />
        </div>
    );
}

//CONSTANTS
//TODO: This array should be loaded from File, instead of hardcoded
const disciplineData = {
    "Intelligent Transport Systems": {
        name: "Intelligent Transport Systems",
        image: {
            src: trafficImage,
            alt: "Intelligent Transport Systems Image",
            but: itsButton,
        },
        description: "Steering the future of Intelligent Transport Systems with precision and innovation.",
        skillsKnowledge: "Expertise in Intelligent Traffic Systems, ITS Networks, and traffic management. Proficient in system design, compliance, and stakeholder coordination.",
        experienceAchievements: [
            "Design Manager for WestConnex 3A at SICE Pty. Ltd., overseeing ITS Networks.",
            "Managed Telephone Systems and Control Rooms design for WestConnex.",
        ],
        websiteSummary: "�lvaro's expertise in Intelligent Transport Systems is highlighted by his impactful role in the WestConnex project, showcasing his skill in managing and advancing ITS technologies."
    },
    "Traffic and Control Systems": {
        name: "Traffic and Control Systems",
        image: {
            src: trafficImage,
            alt: "Traffic and Control Systems Image",
            but: controlButton,
        },
        description: "Mastering the art of Traffic and Control Systems with strategic insight and technical expertise.",
        skillsKnowledge: "Skilled in Plant Control, SCADA systems, and Traffic Incident Management Plans. Expertise in system integration and configuration management.",
        experienceAchievements: [
            "Integration Manager at SICE Pty. Ltd., overseeing complex control system integration.",
            "Developed SPADE, enhancing system configuration management at SICE.",
        ],
        websiteSummary: "�lvaro's command over Traffic and Control Systems is evident in his significant contributions to SICE Pty. Ltd., including the innovative development of SPADE and effective management of large-scale integrations."
    },
    "Technical Management": {
        name: "Technical Management",
        image: {
            src: techImage,
            alt: "Technical Management Image",
            but: techButton,
        },
        description: "In the orchestra of technical management, I'm the maestro, harmonizing tech and teams.",
        skillsKnowledge: "Project management, technical documentation, stakeholder and risk management, process improvement.",
        experienceAchievements: [
            "Founder and Director of Mosaic Minds Pty. Ltd., specializing in ITS Technical Documentation and Workshop Facilitation.",
            "Held various roles at SICE Pty. Ltd., managing teams and overseeing project deliverables.",
        ],
        websiteSummary: "�lvaro's technical management skills are evident in his leadership at Mosaic Minds Pty. Ltd. and his tenure at SICE Pty. Ltd., where he excelled in managing complex projects and developing essential technical documentation."
    },
    "Workshop Facilitation": {
        name: "Workshop Facilitation",
        image: {
            src: facilitationImage,
            alt: "Workshop Facilitation Image",
            but: wksButton,
        },
        description: "I turn workshops into wonder-shops, where ideas bloom like spring flowers.",
        skillsKnowledge: "Integration workshop leadership, stakeholder coordination, safety in design workshop facilitation.",
        experienceAchievements: [
            "Led integration workshops as Integration Manager at SICE Pty. Ltd.",
            "Conducted Safety in Design workshops as Design Manager for WestConnex 3A.",
        ],
        websiteSummary: "�lvaro's ability to facilitate workshops is showcased through his effective leadership in integration and safety in design workshops, reflecting his capability to engage and coordinate with diverse stakeholder groups."
    },
    "User Experience": {
        name: "User Experience",
        image: {
            src: uxImage,
            alt: "User Experience Image",
            but: uxButton,
        },
        description: "Crafting user experiences is my canvas, and I paint with a palette of empathy and innovation.",
        skillsKnowledge: "User-friendly control room design, development of user-centric tools, ergonomic design focus.",
        experienceAchievements: [
            "In charge of ergonomic design of control rooms in WestConnex Projects as Project Engineer.",
            "Developed SPADE and Traceability System at SICE, enhancing user experience.",
        ],
        websiteSummary: "His focus on user experience is highlighted by his involvement in the ergonomic design of control rooms and the development of user-centric tools like SPADE and the Traceability System, prioritizing efficiency and usability."
    },
    "System Engineering Processes": {
        name: "System Engineering Processes",
        image: {
            src: sysEngImage,
            alt: "System Engineering Processes Image",
            but: sysButton,
        },
        description: "Systems engineering is a puzzle, and I fit pieces together with precision and flair.",
        skillsKnowledge: "Process definition and improvement, system automation, design compliance and traceability.",
        experienceAchievements: [
            "Automated asset management process in WestConnex Projects for SICE.",
            "Established semi-Agile process for Fibre Optic Design Drawings management.",
        ],
        websiteSummary: "�lvaro's expertise in system engineering processes is demonstrated through his initiatives in automating asset management and establishing efficient design processes, ensuring compliance and enhancing productivity."
    },
    "About Us": {
        name: "About Us",
        image: {
            src: "https://via.placeholder.com/150",
            alt: "About Us Image",
            but: aboutButton,
        },
        description: "Leading the way in technological innovation and empathetic management.",
        skillsKnowledge: "Leadership, technological innovation, team coaching, empathetic management.",
        experienceAchievements: [
            "Founder of Mosaic Minds Pty. Ltd., showcasing leadership and innovation.",
            "Maintained team morale and collaboration during COVID lockdowns.",
        ],
        websiteSummary: "As the founder of Mosaic Minds Pty. Ltd., �lvaro embodies leadership and a commitment to technological innovation. His empathetic approach to team management is evident in how he maintained team spirit and collaboration during challenging times."
    },
    "Personal Projects": {
        name: "Personal Projects",
        image: {
            src: "https://via.placeholder.com/150",
            alt: "Personal Projects Image",
            but: personalButton,
        },
        description: "A showcase of personal dedication to technology and innovation.",
        skillsKnowledge: "App development, natural language models, 3D printing, electronics, telecommunication technology.",
        experienceAchievements: [
            "Developed an iOS application for International House, University of Sydney.",
            "Created Comillas Mobile, the official university iOS app, ranked 7th in AppStore�s Education top charts in July 2012.",
            "Ongoing research in applications of natural language models.",
            "Development of this website, exemplifying web development skills and attention to detail.",
        ],
        websiteSummary: "�lvaro's personal projects demonstrate his commitment to continuous learning and his passion for technology. They reflect a deep engagement with innovative ideas and a hands-on approach to technology application."
    },
    "Other Initiatives": {
        name: "Other Initiatives",
        image: {
            src: "https://via.placeholder.com/150",
            alt: "Other Initiatives Image",
            but: otherButton,
        },
        description: "Exploring new horizons in the world of import/export.",
        skillsKnowledge: "Strategic planning, market analysis, negotiation, global networking.",
        experienceAchievements: [
            "Currently exploring opportunities in the import/export sector.",
            "Utilizing skills in negotiation and market analysis to identify and capitalize on new business opportunities.",
            "Building a global network to facilitate international trade and partnerships.",
        ],
        websiteSummary: "In addition to his technical and leadership roles, �lvaro is actively exploring opportunities in the import/export industry. This initiative underscores his versatility and entrepreneurial spirit, leveraging his skills in strategic planning and global networking."
    }
};


function App() {
    return (
        <div className="App">
            <DesktopView />
        </div>
    );
}

export default App;
